import router from '../router'
import i18n from '@/i18n.js'

import OrderService from '../services/order.service';

export const order = {
    namespaced: true,
    state: () => ({
        order: null
    }),
    mutations: {
        SET_ORDER(state, data) {
            state.order = data
        }
    },
    actions: {
        // order
        action_upload_attachment({ rootState, commit }, data) {
            data.append('order_id', rootState.id)
            console.log(...data)
            return OrderService.upload_attachment(data).then(
                (response) => {
                    console.log(response);
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('form/SET_NOTIFY', { msg: response.data.message, type: "Success" }, { root: true })
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                }
            );
        },
        action_rejected({ rootState, commit }, data) {
            console.log(...data)
            return OrderService.rejected_order(rootState.id, data).then(
                (response) => {
                    // rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit('form/SET_NOTIFY', { msg: response.data.message, type: "Success" }, { root: true })
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status.name = response.data.data.status.name
                            v.status_id = response.data.data.status_id
                            v.reject_reason = response.data.data.reject_reason

                            return v;
                        }
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit('form/SET_NOTIFY', { msg: error.response.data.message, type: "Danger" }, { root: true })
                }
            );
        },
        approve_order({ rootState, }, id) {
            return OrderService.forward_order(id, { '_method': 'put' }).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == id) {
                            v.status = response.data.data.status
                            v.status_id = response.data.data.status_id
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    if (error.response.status != 401) {
                        return Promise.reject(error);
                    }
                }
            );

        },
        delete({ rootState, }, id) {

            return OrderService.delete_order(id).then(
                (response) => {
                    // console.log(response)
                    rootState.table.items = rootState.table.items.filter((v) => v.id != id);
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    if (error.response.status != 401) {
                        return Promise.reject(error);
                    }
                }
            );

        },
        add({ commit, rootState }, data) {
            data.append('type_id', rootState.id)
            return OrderService.store_order(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form.map((v) => v.disabled = true)
                    commit('form/SET_HIDE_BTN', true, { root: true });
                    commit('SET_ORDER', response.data.data)

                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        update({ rootState }, data) {
            return OrderService.update_order(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "Order",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    if (filter.value != '')
                        formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return OrderService.get_orders(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filter;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};