import Vue from 'vue';
import Vuex from 'vuex';
import { auth } from './auth.module';
import { form } from './form.module';
import { table } from './table.module';
import { user } from './user.module';
import { compound } from './compound.module';
import { unit } from './unit.module';
import { warehouse } from './warehouse.module';
import { item } from './item.module';
import { order } from './order.module';
import { notifications } from './notifications.module';
Vue.use(Vuex);
const myPlugin = store => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // console.log(mutation)
        switch (mutation.type) {
            case 'form/SAVE_FORM_DATA':
                console.log('user details updated');
                if (mutation.payload.id) {
                    store.dispatch(mutation.payload.name, { data: state.form.form_data, id: mutation.payload.id })
                } else {
                    store.dispatch(mutation.payload.name, { data: state.form.form_data })
                }
                break;
            default:
                break;
        }
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
    })
};
export default new Vuex.Store({
    state: () => ({
        collection: '',
        name_function: '',
        id: '',
        parent_page: '',
        url: null,
        card: {},
    }),

    mutations: {
        SET_COLLECTION(state, data) {
            state.collection = data
        },
        SET_URL(state, data) {
            state.url = data
        },
        SET_FUNCTION(state, data) {
            state.name_function = data;
        },
        SET_ID(state, data) {
            state.id = data;
        },
        SET_CARD(state, data) {
            state.card = data;
        },
        SET_CARD_LOADING(state, data) {
            state.card.loading = data;
        },
        SET_PARENT_PAGE(state, data) {
            state.parent_page = data;
        },
    },
    actions: {
        ResetData({ state, rootState }) {
            rootState.table.btn_table = [];
            rootState.table.items = [];
            rootState.table.data_extend = {};
            rootState.table.data_expand = {};
            rootState.table.headers = [];
            rootState.table.btns = [];
            rootState.table.chack_permission = 'd-none';
            rootState.table.pagination = false;
            rootState.table.paginate = {
                page: 1,
                itemsPerPage: 0,
                total: 0,
                row_pre_page: false
            };
            rootState.table.select_view = false
            rootState.table.bulk_selected = [],
                state.url = null
            state.name_function = ''
            state.collection = ''
            state.id = '',
                state.parent_page = '',

                // form
                rootState.form.filters = [];
            rootState.form.formData = null;
            rootState.form.form_data = {};
            rootState.form.hide_btn = false,
                rootState.form.style_form = [];
            rootState.form.action = '';
            rootState.form.notify = {
                color: null,
                visible: false,
                msg: '',
                note: ''
            };
            rootState.form.modal = {};
            rootState.form.loader = false;
            rootState.form.dialog = false;
            rootState.form.stepper = {}

            return true;
        },

    },
    plugins: [myPlugin],
    modules: {
        auth,
        form,
        table,
        user, notifications,
        compound, unit, warehouse,
        item, order,
    }
});