import AuthCoverLayout from "../views/Layout/AuthCoverLayout";
const LogIn = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Current/Auth/Login.vue"
    );

const Email = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Current/Auth/Email.vue"
    );

const ResetPassword = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Current/Auth/ResetPassword.vue"
    );

const Signup = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/views/Current/Auth/Register.vue"
    );
export default {

    path: "/",
    component: AuthCoverLayout,
    name: "Login-Page",
    children: [{
        path: "/login",
        name: "Login",
        component: LogIn,
    },

    {
        path: "/reset_password",
        name: "Email",
        component: Email,
    },
    {
        path: "/reset",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: "/sign-up",
        name: "Signup",
        component: Signup,
    }
    ],
}