export default function permission({ to, nextMiddleware, contextNext, store }) {
    // alert(1)
    if (store.state.auth.permissions.length == 0) {
        // alert(2)
        store.dispatch("auth/getPermission").then((res) => {
            checkPer();
        });
    } else {
        // alert(3)
        checkPer();
    }

    function checkPer() {
        if (to.meta.permissions) {

            if (!store.state.auth.permissions.includes(to.meta.permissions)) {
                return contextNext({
                    path: "/403",
                });
            }
        }
    }

    return nextMiddleware();
}
