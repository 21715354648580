// import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function auth({
    nextMiddleware,

    store,


    contextNext,
}) {
    if (!store.state.auth.loggedIn) {
        // alert(1)
        // saveRedirectionIntoStorage(to.fullPath);
        return contextNext({
            path: "/login",
        });
    } else {
        // alert(2)

        console.log(store.state.auth.user)
        if (
            store.state.auth.user == null
        ) {

            return contextNext({
                path: "/login",
            });
        }
        store.dispatch("notifications/getNotifocations");
        return nextMiddleware();
    }
}
