
import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const roles = {
    path: "/",
    component: DashboardLayout,
    name: "Roles-Managment",
    children: [{
        path: "/roles",
        name: "Roles",
        component: () =>
            import("@/views/Current/Roles/index.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'user-role-index',
        },
    },
    {
        path: "/roles/create",
        name: "Create Role",
        component: () =>
            import("@/views/Current/Roles/create.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'role-create',
        },
    },
    {
        path: "/roles/:id",
        name: "Update Role",
        component: () =>
            import("@/views/Current/Roles/update.vue"),
        meta: {
            groupName: "Roles",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'role-update',
        },
    },

    ]
}

export const users = {
    path: "/",
    component: DashboardLayout,
    name: "Users-Managment",
    children: [
        {
            path: "/profile",
            name: "profileUser",
            component: () =>
                import("@/views/Current/profile.vue"),
            meta: {
                groupName: "Users",
                middlewares: [
                    Middleware.auth,
                ],
                permissions: '',
            },
        },
        {
            path: "/users",
            name: "Users",
            component: () =>
                import("@/views/Current/Users/index.vue"),
            meta: {
                groupName: "Users",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                ],
                permissions: 'user-index',
            },
        },
        {
            path: "/users/create",
            name: "CreateUser",
            component: () =>
                import("@/views/Current/Users/create.vue"),
            meta: {
                groupName: "Users",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                ],
                permissions: 'user-create',
            },
        },
        {
            path: "/users/edit/:id",
            name: "UpdateUser",
            component: () =>
                import("@/views/Current/Users/update.vue"),
            meta: {
                groupName: "Users",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                ],
                permissions: 'user-update',
            },
        },
        {
            path: "/users/view/:id",
            name: "Detailsser",
            component: () =>
                import("@/views/Current/Users/details.vue"),
            meta: {
                groupName: "Users",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                ],
                permissions: 'user-details',
            },
        },


    ]
}


