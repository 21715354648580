var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{staticClass:"px-0 toolbar-content-padding-y-none",class:_vm.navbarFixed ? 'position-sticky    z-index-sticky py-2' : '',attrs:{"color":_vm.background,"height":"auto","flat":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center",staticStyle:{"width":"100%"}},[_c('div',{staticClass:"drawer-toggler pa-5 ms-6 cursor-pointer",class:{ active: _vm.togglerActive },staticStyle:{"padding-right":"0 !important"},on:{"click":_vm.minifyDrawer}},[_c('div',{staticClass:"drawer-toggler-inner"},[_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"}),_c('i',{staticClass:"drawer-toggler-line bg-body"})])]),_c('div',[_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","offset-x":"","min-width":"300","max-width":"300","max-height":"300"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body",class:[
              { 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },
              _vm.classLang(6) ],attrs:{"icon":"","ripple":false,"color":_vm.linkColor,"small":""},on:{"click":_vm.clear_notification}},'v-btn',attrs,false),on),[(_vm.unread_notify != 0)?_c('v-badge',{attrs:{"color":"red","overlap":"","content":_vm.unread_notify}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fa fa-bell text-lg")])],1):_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fa fa-bell text-sm")])],1)]}}])},[_c('v-list',{staticClass:"py-0"},[_vm._l((_vm.notifications),function(item,i){return _c('v-list-item',{key:i,staticClass:"pa-4 list-item-hover-active",class:item.is_seen == 0 ? 'not_seen' : '',on:{"click":function($event){return _vm.see_notify(item)}}},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0 white-space-break"},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-sm font-weight-normal mb-1 text-typo"},[_vm._v(" "+_vm._s(item.message)+" ")])])],1)],1),_c('p',{staticClass:"text-xs text-secondary mb-0"},[_c('v-icon',{staticClass:"text-secondary",attrs:{"size":"12"}},[_vm._v("fa fa-clock me-1")]),_vm._v(" "+_vm._s(item.created_at)+" ")],1)],1)],1)}),(_vm.read_more)?_c('v-list-item',{staticClass:"border-top list-item-hover-active text-center see_more",staticStyle:{"background":"#d1d5d5"}},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 list-notify text-typo font-weight-600 mb-0"},[_c('div',[_c('v-btn',{staticClass:"w-100 text-xs font-weight-normal text-typo box-shadow-none",staticStyle:{"background-color":"transparent"},nativeOn:{"!click":function($event){$event.stopPropagation();return _vm.read_more_method.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("see more"))+" ")])],1)])],1)],1):_vm._e()],2)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","min-width":"150","max-width":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-body",class:[
              { 'btn-dark-hover': !_vm.hasBg, 'btn-hover': _vm.hasBg },
              _vm.classLang(6) ],attrs:{"icon":"","ripple":false,"color":_vm.linkColor,"small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fa fa-globe text-sm")])],1)]}}])},[_c('v-list',{staticClass:"py-0"},_vm._l((_vm.language),function(item,i){return _c('v-list-item',{key:i,staticClass:"px-4 py-2 list-item-hover-active"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',{on:{"click":function($event){return _vm.change_lang(item.abbr)}}},[_c('h6',{staticClass:"text-sm font-weight-normal mb-1 text-typo",domProps:{"innerHTML":_vm._s(item.title)}})])],1)],1)],1)],1)}),1)],1),_c('v-menu',{attrs:{"transition":"slide-y-transition","offset-y":"","min-width":"250","max-width":"250","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(_vm.user)?_c('v-btn',_vm._g(_vm._b({staticClass:"text-body btn-hover box-shadow-none",staticStyle:{"box-shadow":"none","padding":"0"},attrs:{"ripple":false,"color":"transparent"}},'v-btn',attrs,false),on),[_c('div',{staticClass:"d-flex flex-column align-center"},[_c('span',[_vm._v(_vm._s(_vm.fullName))]),_c('span',[_vm._v(" "+_vm._s(_vm.accountType))])]),_c('v-avatar',[(_vm.avatar != null)?_c('img',{attrs:{"src":_vm.$baseURL + 'storage/users/' + _vm.avatar,"alt":""}}):_c('v-icon',{attrs:{"size":"40"}},[_vm._v("mdi-account")])],1)],1):_vm._e()]}}])},[_c('v-list',{staticClass:"py-0"},[_c('v-list-item',{staticClass:"pa-4 list-item-hover-active"},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":"/profile"}},[_c('h6',{staticClass:"text-sm font-weight-normal mb-1 text-typo"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fa fa-user me-sm-2 text-sm")]),_vm._v(" "+_vm._s(_vm.$t("profile"))+" ")],1)])],1)],1)],1)],1)],1),_c('v-list-item',{staticClass:"pa-4 list-item-hover-active",on:{"click":_vm.logout}},[_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"text-body-2 ls-0 text-typo font-weight-600 mb-0"},[_c('v-row',[_c('v-col',[_c('h6',{staticClass:"text-sm font-weight-normal mb-1 text-typo"},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v("fas fa-sign-out-alt me-sm-2 text-sm")]),_vm._v(" "+_vm._s(_vm.$t("logout"))+" ")],1)])],1)],1)],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }