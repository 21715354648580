import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const compounds = {
    path: "/",
    component: DashboardLayout,
    name: "Compound-Managment",
    children: [{
        path: "/compounds",
        name: "Compound",
        component: () =>
            import("@/views/Current/Compound/index.vue"),
        meta: {
            groupName: "Compound",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-index',
        },
    },
    {
        path: "/compounds/create",
        name: "CreateCompound",
        component: () =>
            import("@/views/Current/Compound/create.vue"),
        meta: {
            groupName: "Compound",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-create',
        },
    },
    {
        path: "/compounds/edit/:id",
        name: "UpdateCompound",
        component: () =>
            import("@/views/Current/Compound/update.vue"),
        meta: {
            groupName: "Compound",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-update',
        },
    },

    {
        path: "/compounds/details/:id",
        name: "DetailsCompound",
        component: () =>
            import("@/views/Current/Compound/details.vue"),
        meta: {
            groupName: "Compound",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-update',
        },
    },

    {
        path: "/compounds/:compound/warehouses",
        name: "Warehouse",
        component: () =>
            import("@/views/Current/Compound/warehouse/index.vue"),
        meta: {
            groupName: "Warehouse",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'warehouse-index',
        },
    },
    {
        path: "/compounds/:compound/warehouse/:id",
        name: "warehouse",
        component: () =>
            import("@/views/Current/Compound/warehouse/details.vue"),
        meta: {
            groupName: "Warehouse",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'warehouse-details',
        },
    },

    {
        path: "/compounds/:compound/units",
        name: "Unit",
        component: () =>
            import("@/views/Current/Compound/units/index.vue"),
        meta: {
            groupName: "Unit",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-unit-index',
        },
    },
    {
        path: "/compounds/:compound/units/:id",
        name: "units",
        component: () =>
            import("@/views/Current/Compound/units/details.vue"),
        meta: {
            groupName: "units",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'unit-details',
        },
    },

    ]
}

export const categories = {
    path: "/",
    component: DashboardLayout,
    name: "UnitCategory-Managment",
    children: [{
        path: "/unit-categories",
        name: "UnitCategory",
        component: () =>
            import("@/views/Current/Compound/units/categories.vue"),
        meta: {
            groupName: "UnitCategory",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'unit-category-index',
        },
    },
    ]
}

export const units = {
    path: "/",
    component: DashboardLayout,
    name: "Unit-Managment",
    children: [{
        path: "/units",
        name: "Unit",
        component: () =>
            import("@/views/Current/Compound/units/index.vue"),
        meta: {
            groupName: "Unit",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'compound-unit-index',
        },
    },
    {
        path: "/units/:id",
        name: "units",
        component: () =>
            import("@/views/Current/Compound/units/details.vue"),
        meta: {
            groupName: "units",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'unit-details',
        },
    },
    ]
}

export const wharehouses = {
    path: "/",
    component: DashboardLayout,
    name: "Warehouse-Managment",
    children: [{
        path: "/warehouses",
        name: "Warehouse",
        component: () =>
            import("@/views/Current/Compound/warehouse/index.vue"),
        meta: {
            groupName: "Warehouse",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'warehouse-index',
        },
    },
    {
        path: "/warehouse/:id",
        name: "Warehouse",
        component: () =>
            import("@/views/Current/Compound/warehouse/details.vue"),
        meta: {
            groupName: "Warehouse",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'warehouse-details',
        },
    },
    ]
}
