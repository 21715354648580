export default {
    methods: {
        classLang(num) {
            if (this.$vuetify.rtl)
                return `ml-${num}`;
            else return `mr-${num}`;
        },
        classLangReverse(num) {
            if (this.$vuetify.rtl)
                return `mr-${num}`;
            else return `ml-${num}`;
        },
    },
    computed: {
        borderColor() {
            if (this.$vuetify.rtl)
                return 'border-right-color';
            else return 'border-left-color';
        }

    }
}