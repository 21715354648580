import router from '../router'
import i18n from '@/i18n.js'
import Vue from "vue";

import CoumpondService from '../services/compound.service';
export const compound = {
    namespaced: true,
    state: () => ({
        compound: null
    }),
    mutations: {
        SET_PROJECT(state, data) {
            state.compound = data
        }
    },
    actions: {
        // compound
        delete({ rootState, }, item) {
            Vue.swal({
                title: i18n.t("are u sure?"),
                text: i18n.t("You won't be able to revert this!"),
                type: "warning",
                showCancelButton: true,
                customClass: {
                    confirmButton: "btn bg-gradient-success",
                    cancelButton: "btn bg-gradient-danger",
                },
                confirmButtonText: i18n.t("yes , delete it"),
                cancelButtonText: i18n.t("no , cancel"),
                reverseButtons: true,
            }).then((result) => {
                if (result.value) {
                    return CoumpondService.delete_compound(item.id).then(
                        (response) => {
                            // console.log(response)
                            rootState.table.items = rootState.table.items.filter((v) => v.id != item.id);
                            Vue.swal.fire(i18n.t("Done"), response.data.message, "success");
                        },
                        (error) => {
                            console.log(error);
                            if (error.response.status != 401) {
                                Vue.swal.fire(
                                    i18n.t("Error"),
                                    i18n.t("There error please try again"),
                                    "error"
                                );
                            }
                        }
                    );
                } else if (
                    /* Read more about handling dismissals below */
                    result.dismiss === Vue.swal.DismissReason.cancel
                ) {
                    Vue.swal.fire(i18n.t("Cancel"), i18n.t("Cancelled Proccess"), "error");
                }
            });
        },
        add({ rootState }, data) {
            console.log(...data)
            var clinic = data.get('clinic')
            console.log('clinic', clinic)
            clinic ?
                data.append('clinic', 1) : data.append('clinic', 0)

            return CoumpondService.store_compound(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "Compound",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },
        update({ rootState }, data) {
            data.append("_method", "put")
            return CoumpondService.update_compound(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    // rootState.form.notify = {
                    //     msg: response.data.message,
                    //     type: "Success",
                    // };
                    router.push({
                        name: "Compound",
                        params: { message: { msg: response.data.message, type: "Success" } },
                    });
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        // assign_user({ commit, rootState }, item) {
        //     commit('form/SET_DIALOG', true, { root: true })
        //     rootState.id = item.id
        //     commit("SET_FUNCTION", "action_assign_user", { root: true });
        // },
        action_assign_user({ commit, rootState }, data) {
            data.append('compound_id', rootState.id)
            return CoumpondService.assign_user_to_compound(data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    commit('form/SET_DIALOG', false, { root: true })
                    // router.push({
                    //     name: "Compound",
                    //     params: { message: { msg: response.data.message, type: "Success" } },
                    // });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },

        action_assign_other_user({ commit, rootState }, data) {
            data.append('compound_id', rootState.id)
            return CoumpondService.assign_other_users_to_compound(rootState.id, data).then(
                (response) => {
                    console.log(response);
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    commit('form/SET_DIALOG', false, { root: true })
                    // router.push({
                    //     name: "Compound",
                    //     params: { message: { msg: response.data.message, type: "Success" } },
                    // });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                }
            );

        },



        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    if (filter.value != '') {
                        formData.append(filter.name, filter.value);
                    }
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return CoumpondService.get_compounds(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filter;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("there is a problem please try again"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};