import DashboardLayout from "../views/Layout/DashboardLayout.vue";
import Middleware from "../middleware";
export const orders = {
    path: "/",
    component: DashboardLayout,
    name: "Order-Managment",
    children: [{
        path: "/orders",
        name: "Order",
        component: () =>
            import("@/views/Current/Order/index.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'order-index',
        },
    },
    {
        path: "/orders/create",
        name: "CreateOrder",
        component: () =>
            import("@/views/Current/Order/create.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'order-create',
        },
    },
    {
        path: "/orders/edit/:id",
        name: "UpdateOrder",
        component: () =>
            import("@/views/Current/Order/update.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'order-update',
        },
    },
    {
        path: "/orders/details/:id",
        name: "DetailsOrder",
        component: () =>
            import("@/views/Current/Order/details.vue"),
        meta: {
            groupName: "Order",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
            ],
            permissions: 'order-details',
        },
    },



        // {
        //     path: "/orders/:order/warehouses",
        //     name: "Warehouse",
        //     component: () =>
        //         import("@/views/Current/Order/warehouse/index.vue"),
        //     meta: {
        //         groupName: "Warehouse",
        //         middlewares: [
        //             Middleware.auth,
        //             Middleware.permission,
        //         ],
        //         permissions: 'warehouse-index',
        //     },
        // },



    ]
}


