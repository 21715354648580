<template>
  <v-app class="bg-white">
    <v-main class="auth-pages">
      <div
        class="login"
        :style="`background-image: url(${require('@/assets/img/current/Picture2.png')});`"
      >
        <div class="overlay">
          <div class="content">
            <v-container>
              <div class="row mb-0">
                <!-- <div class="col-md-3"></div> -->
                <div
                  class="col-md-7 col-lg-5 d-flex justify-center flex-column"
                >
                  <div class="">
                    <v-card
                      class="pa-6 card-shadow border-radius-xl bg-white-transparent"
                    >
                      <div class="w-100">
                        <fade-transition
                          :duration="200"
                          origin="center top"
                          mode="out-in"
                        >
                          <!-- your content here -->
                          <router-view></router-view>
                        </fade-transition>
                      </div>
                    </v-card>
                  </div>
                </div>
                <div class="d-md-block d-none col-md-5 offset-lg-2">
                  <img
                    style="max-width: 97%"
                    src="@/assets/img/current/logo2.png"
                    alt=""
                  />
                  <div class="text-center">
                    <p class="title-1">الخدمات الإلكترونية</p>
                    <p class="title-2">نظام إدارة الأصول والمستودعات</p>
                  </div>
                </div>
              </div>
            </v-container>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {};
  },

  mounted() {},
  created() {
    this.$vuetify.rtl = true;
  },
};
</script>
<style lang="css">
html:lang(ar) .section-form {
  direction: rtl;
  /* text-align: right; */
}
</style>
