
class ItemService {

    get_items(page, data) {
        return axios.post('iteams?page=' + page, data);
    }
    create_item() {
        return axios.get('iteams/create');
    }
    store_item(data) {
        return axios.post('iteams/store', data)
    }
    edit_item(id) {
        return axios.get('iteams/edit/' + id)
    }
    update_item(id, data) {

        return axios.post('iteams/update/' + id, data)
    }
    delete_item(id) {
        return axios.delete('iteams/destroy/' + id)
    }


    get_item_categories() {
        return axios.get('iteams/category');
    }
    store_item_category(data) {
        return axios.post('iteams/category/store', data)
    }
    update_item_category(id, data) {
        data.append('_method', 'put')
        return axios.post('iteams/category/update/' + id, data)
    }
    delete_item_category(id) {
        return axios.delete('iteams/category/destroy/' + id)
    }
}
export default new ItemService();