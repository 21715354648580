export const items = {
    action: "mdi-cart-outline",
    title: "items",
    permission: "item",
    items: [
        {
            prefix: "O",
            title: "view items categories",
            link: "/items-categories",
            permission: "category-item-index",
        },
        {
            prefix: "O",
            title: "view items",
            link: "/items",
            permission: "item-index",
        },
    ]
}
